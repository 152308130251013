import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import logo from "../images/footer/logo footer.png"
import appStore from "../images/footer/app-store-badge.svg"
import googlePlay from "../images/footer/google-play-badge.svg"
import facebook from "../images/footer/facebook.svg"
import twitter from "../images/footer/twitter.svg"
import instagram from "../images/footer/instagram.svg"
import device from "./device"
import useLinks from "./hooks/useLinks"
import Img from "gatsby-image"

const Wrapper = styled.footer`
  padding: 8rem 0 4rem 0;
  background: var(--black);
  color: var(--white);
  text-transform: uppercase;
  ${device.small`padding: 8rem 0 2rem 0;`}
  .container {
    max-width: 1500px;
    width: 70%;
    margin: 0 auto;
    ${device.medium`width: 80%;`}
    ${device.small`width: var(--spread);`}
    .divider {
      height: 1px;
      background: var(--grey);
      margin: 2rem 0;
    }
  }
`

const UpperFooter = styled.div`
  display: flex;
  align-items: center;
  ${device.small`display: block;`}
  .contact {
    flex: 1;
    .image-wrapper {
      img {
        width: 150px;
        ${device.small`width: 120px;`}
      }
    }
    .number {
      letter-spacing: 1px;
      margin: 1rem 0;
      font-size: 0.7rem;
    }
  }
  .links {
    display: flex;
    ${device.small`display: block;`}
    .category {
      margin-left: 4rem;
      ${device.small`margin: 3rem 0;`}
      h3 {
        font-size: 1.1rem;
      }
    }
    .group {
      margin-top: 2rem;
      a {
        display: block;
        font-size: 0.7rem;
        color: var(--grey);
        margin: 1rem 0;
        color: var(--grey);
      }
    }
  }
`
const LowerFooter = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  ${device.small`flex-direction: column-reverse; align-items: flex-start;`}
  .copyright {
    flex: 1;
    font-size: 0.7rem;
    ${device.small`margin: 2rem 0; width: 100%; line-height: 1.6;`}
    @media(min-width: 992px) {
      flex: 0;
    }
  }
  .links {
    display: flex;
    align-items: center;
    ${device.small`flex-direction: column; align-items: flex-start;`}
  }
  .app-links {
    ${device.small`display: flex; flex-direction: column-reverse;`}
    a {
      margin-left: 1rem;
      ${device.small`display: block; margin: 0.5rem 0;`}
      img {
        width: 120px;
      }
    }
  }
  .social-links {
    ${device.small`margin: 1rem 0 2rem 0; width: 120px; display: flex; justify-content: space-between;`}
    a {
      margin-right: 1rem;
      ${device.small`margin-right: 0rem;`}
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .nexvelLogoContainer {
    font-size: 0.7rem;
    text-align: center;
  }
`

const Footer = () => {
  const extLinks = useLinks()

  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        menuItems(where: { location: FOOTER, parentDatabaseId: 0 }) {
          nodes {
            label
            path
            url
            childItems {
              nodes {
                label
                path
                url
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          url
        }
      }
      file(relativePath: { eq: "nexvel-logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <div className="container">
        <UpperFooter>
          <div className="contact">
            <div className="image-wrapper">
              <img src={logo} alt="King Transportation Logo" />
            </div>
            {extLinks.toll_free && (
              <div className="toll-free number">
                Toll-Free :{" "}
                <a href={`tel:${extLinks.toll_free}`}>{extLinks.toll_free}</a>
              </div>
            )}
            <div className="local number">
              <a href={`tel:${extLinks.local}`}>{extLinks.local}</a>
            </div>
          </div>
          <div className="links">
            {data.wpgraphql.menuItems.nodes.map((item, i) => (
              <div className="category" key={i}>
                <h3>{item.label}</h3>
                <div className="group">
                  {item.childItems.nodes.map((child_item, j) => {
                    const isInternal = child_item.url.includes(
                      data.site.siteMetadata.url
                    )
                    if (isInternal) {
                      return (
                        <Link
                          to={child_item.url.replace(
                            data.site.siteMetadata.url,
                            ""
                          )}
                          key={j}
                        >
                          {child_item.label}
                        </Link>
                      )
                    } else {
                      return (
                        <a
                          href={child_item.url}
                          key={j}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {child_item.label}
                        </a>
                      )
                    }
                  })}
                </div>
              </div>
            ))}
          </div>
        </UpperFooter>

        <div className="divider"></div>

        <LowerFooter>
          <div className="copyright">
            © {new Date().getFullYear()} King Transportation. All rights
            reserved.
          </div>
          <div className="nexvelLogoContainer">
            <a href="https://www.nexvelsolutions.com/" target="_blank">
              Website Designed by Nexvel Solutions <br />
              <Img fixed={data.file.childImageSharp.fixed} />
            </a>
          </div>
          <div className="links">
            <div className="social-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={extLinks.facebook}
              >
                <img src={facebook} alt="Facebook link" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={extLinks.twitter}
              >
                <img src={twitter} alt="Twitter link" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={extLinks.instagram}
              >
                <img src={instagram} alt="Instagram link" />
              </a>
            </div>
            <div className="app-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={extLinks.app_store}
              >
                <img src={appStore} alt="App Store" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={extLinks.google_play}
              >
                <img src={googlePlay} alt="Google Play Store" />
              </a>
            </div>
          </div>
        </LowerFooter>
      </div>
    </Wrapper>
  )
}

export default Footer
